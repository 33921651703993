import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/AuthContext'
import i18next from 'i18next'
import { SettingsContext } from '../context/SettingsContext/SettingsContext'
import cookies from 'js-cookie'

const DefaultLanguageSetter = () => {
  const { user } = useContext(AuthContext)
  const { dispatch, settings: contSet } = useContext(SettingsContext)
  const [settings, setSettings] = useState(contSet)
  const cookieLang = cookies.get('i18next')

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const res = await axios.get(`/userSettings/${user?._id}`)
        setSettings(res.data.settings)
        if (res.data.settings)
          dispatch({
            type: 'UPDATE',
            payload: res.data.settings,
          })
      } catch (error) {
        console.log(error)
      }
    }
    if (user?._id) fetchSettings()
  }, [user?._id])

  const langCode = settings && settings?.Language?.toLowerCase()?.slice(0, 2)

  const defaultBrowerLang = navigator.language

  useEffect(() => {
    if (cookieLang && !langCode) {
      // in case of latency in settings getting i will depened on cookies
      i18next.changeLanguage(cookieLang)
    } else if (settings?.Language) {
      i18next.changeLanguage(langCode)
      cookies.set('i18next', langCode, {
        domain: '.hutupia.com',
        expires: 7,
        secure: true,
      })
    } else {
      i18next.changeLanguage(defaultBrowerLang)
    }
  }, [cookieLang, user?._id, settings?.Language])
}

export default DefaultLanguageSetter
