import React, { createContext, useEffect, useReducer } from 'react'
import AuthReducer from './AuthReducer'
import CryptoJS from 'crypto-js'
import cookies from 'js-cookie'
import axios from 'axios'

const USER_DECRYPT_SECRET_KEY = String(process.env.USER_DECRYPT_SECRET_KEY)
// const encryptedUser = cookies.get('user')
// for local testing, the encrypted data of the user in cookies user
const encryptedUser =
  'U2FsdGVkX19inVZokajb+70hVQCcEOpJQl67Ohj1QKdnozyiyYvqjEanMpaBx4g5gUBnovKnbKNTl6F8Rq+Q6gPol1JX93w87nS/B1tqZ3KWacZg8CzBs3mK+eWd9WGuGL2Ptv6GCNFuv3mpNm/3kN11/eEJ8vFz2FNoP8WcIib877j/lFIEEcB+qr+cmXLnv5nhjxLne4ZtbMIS/mWsvxjWpOejHdQCYJL6/C43Q7xHijbe+D9NhrF9b+xIaqZwQSP7ylDPT4M/dlUloEmYaDuRzr9pAxgGpFanKP352tSK/IbcvPmxXBAL+mXy9dy/OlBo+kmScqNrv3B07TaixU+vubUoygXy5QFvEfdj3r7SMFM9O7dNLNXlK4EzG/zmsk6SX+Ta7cq0BjHb8+WeaUHW6BJc8GFtRqh8/oJm36/4TIeBq97geL7p8XDrKWb7STfbpLo2w/r/HzNvDjSxjRTO25m5/Nea/zym0t6een02ymqD8Gz7RLSc+C2G9wAMymFprEqfLUZ/HrPisvH40qQbjX0QUhU7kZ0wdaUgSp2e4hCbDea0XyAASn/iZ7tFeTmVMaYKl2DRfVj+uCnRDh04ubTFvIZjecBTd9nqg+YfOmTcaCKsnWmpQoFN+AAKqh3TFrYAGmU2MQj94/5o8v0rOV9D4WQupDocbcGSLmffX84OIBgGncdgZgv9V6XkPlJ9wCxolvLxqsT9tR66i3Qmr8Z0Wpisv1JbUVoTTuFwurkn5+Q5g6KGwBRfO5tp'

const INITIAL_STATE = {
  user: null,
  isFetching: false,
  error: false,
}

try {
  if (encryptedUser) {
    const decryptedData = CryptoJS.AES.decrypt(
      encryptedUser,
      USER_DECRYPT_SECRET_KEY
    ).toString(CryptoJS.enc.Utf8)

    const parsedUser = JSON.parse(decryptedData)
    if (decryptedData) {
      INITIAL_STATE.user = parsedUser
      const getUser = async () => {
        const fullUser = await axios.get(
          // this is invoked even before th app, so the context does not recognise base url set in the app.js for axios backend
          `https://hutupia-backend.onrender.com/api/v1/users/${parsedUser?._id}`
        )
        INITIAL_STATE.user = fullUser
      }
      getUser()
    }
  }
} catch (error) {
  console.error(error)
}

export const AuthContext = createContext(INITIAL_STATE)

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE)

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
