import React, { Suspense, useContext, useEffect, useRef } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from './context/AuthContext'
import BrokenLink from './pages/BrokenLink'
import LazyHutupiaCover from './pages/LazyHutupiaCover'
import ScrollToUp from './pages/ScrollToUp'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import DefaultLanguageSetter from './pages/DefaultLanguageSetter'
import DefaultLocationSetter from './pages/DefaultLocationSetter'
import PageDirection from './pages/PageDirection'
import moment from 'moment'
import { io } from 'socket.io-client'
import { SettingsContext } from './context/SettingsContext/SettingsContext'
import axios from 'axios'
import i18next from 'i18next'

const ProjectProfilePage = React.lazy(() =>
  import('./pages/ProjectProfilePage')
)
const ProjectsScreenPage = React.lazy(() =>
  import('./pages/ProjectsScreenPage')
)
const RedirectPage = React.lazy(() => import('./pages/RedirectPage'))
const lang = i18next.language
const rtl = lang === 'ar'

async function arabic() {
  axios.defaults.baseURL = 'https://hutupia-backend.onrender.com/api/v1'

  if (document.body.dir === 'rtl' || rtl) {
    await import('moment/locale/ar')
  } else if (document.body.dir === 'ltr' || !rtl) {
    await moment.updateLocale('en')
  }
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'ar'],
    fallbackLng: 'en',
    detection: {
      order: [
        'cookie',
        'localStorage',
        'querystring',
        'sessionStorage',
        'path',
        'navigator',
        'subdomain',
        'htmlTag',
      ],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
    },
    ns: [
      'translation',
      'AddOnBusinessModel',
      'AffiliationBusinessModel',
      'AikidoBusinessModel',
      'AnynoumousMarket',
      'AuctionBusinessModel',
      'ReverseAuction',
      'BarterBusinessModel',
      'CrossSellingBusinessModel',
      'CashMachineBusinessModel',
      'Crowdfunding',
      'Crowdsourcing',
      'CustomerLoyaltyBusinessModel',
      'AugmentedNetworkBusinessModel',
      'DigitisationBusinessModel',
      'DirectSellingBusinessModel',
      'ECommerceBusinessModel',
      'OnlineOfflineHybrid',
      'FreemiumBusinessModel',
      'ExperienceSellingBusinessModel',
      'FlatRateBusinessModel',
      'FractionalOwnershipBusinessModel',
      'FromPushToPullBusinessModel',
      'GuaranteedAvailabilityBusinessModel',
      'HiddenRevenueBusinessModel',
      'IngredientBrandingBusinessModel',
      'IntegratorBusinessModel',
      'FranchisingBusinessModel',
      'LayerPlayerBusinessModel',
      'LeverageCustomerDataBusinessModel',
      'LicensingBusinessModel',
      'LockInBusinessModel',
      'LongTailBusinessModel',
      'MakeMoreofIt',
      'MassCustomisationBusinessModel',
      'MyAlternative',
      'NoFrillsBusinessModel',
      'OpenBusiness',
      'OpenSourceBusinessModel',
      'OrchestratorBusinessModel',
      'PayPerUse',
      'PayWhatYouWantBusinessModel',
      'PeertoPeerBusinessModel',
      'PerformanceBasedContracting',
      'RazorAndBladeBusinessModel',
      'RentInsteadOfBuyBusinessModel',
      'RevenueSharing',
      'ReverseEngineeringBusinessModel',
      'ReverseInnovationBusinessModel',
      'RobinHoodBusinessModel',
      'ComplementaryShopsBusinessModel',
      'SelfService',
      'ShopInShopBusinessModel',
      'SolutionProviderBusinessModel',
      'SubscriptionBusinessModel',
      'SupermarketBusinessModel',
      'TargetthePoorBusinessModel',
      'TrashToCashBusinessModel',
      'TwoSidedMarketBusinessModel',
      'UltimateLuxuryBusinessModel',
      'UserDesignBusinessModel',
      'WhiteLabelBusinessModel',
      'DropshippingBusinessModel',
      'MembershipBusinessModel',
      'PayPerClickBusinessModel',
      'PureBundlingBusinessModel',
      'TimeShareBusinessModel',
      'TiredPrcingBusinessModel',
      'UpsellingBusinessModel',
      'EcosystemBusinessModel',
      'MultiLevelMarketingBusinessModel',
      'NetworkBusinessModel',
      'SponsorshipBusinessModel',
      'TransactionBasedBusinessModel',
      'ConsultingAndProfessionalServices',
      'FunctionAsAService',
      'HybridCloud',
      'InfrastructureAsAService',
      'PlatformAsAService',
      'ResellerAndReferralPrograms',
      'SoftwareAsAService',
      'SoftwareLicensing',
      'Barriers',
      'BusinessDefinition',
      'BusinessPolarity',
      'BuyerPower',
      'Choice',
      'CommoditySlide',
      'CustomerBehaviour',
      'CustomerCatchingAndKeeping',
      'CustomerDescription',
      'CustomerHistory',
      'CustomerMigration',
      'CustomerMotivation',
      'CustomerPerception',
      'CustomerSatisfaction',
      'Differentiation',
      'DirectSaleSegments',
      'ForecastingTomorrow',
      'FuturePositions',
      'LatentNeeds',
      'MarketingDifferentSegments',
      'MarketingInformationSystems',
      'MarketingRevenues',
      'NewEntrants',
      'Positioning',
      'Relationships',
      'RetentionAsLoyalty',
      'SegmentLockOut',
      'SegmentMigration',
      'SegmentPriotirization',
      'SegmentationEffects',
      'ShareholdersvsCustomerRetention',
      'Substitutes',
      'SupplierPower',
      'TheSegmentOfOne',
      'ValueMigration',
      'BrandBreaking',
      'BrandCosts',
      'Branding',
      'BrandLeadership',
      'BrandSecrets',
      'BrandsManagment',
    ],
    defaultNS: 'translation',
  })

function App() {
  DefaultLanguageSetter()
  PageDirection()

  const { user } = useContext(AuthContext)
  DefaultLocationSetter()
  const pathname = useLocation().pathname
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    arabic()
  }, [document.body.dir, rtl])

  const socket = useRef()
  useEffect(() => {
    socket.current = io('https://hutupia-socket.onrender.com')
  }, [user?._id])

  const { settings } = useContext(SettingsContext)
  const isDarkModeActive = settings?.Mode === 'Dark'

  useEffect(() => {
    document.body.style.background = isDarkModeActive
      ? 'black'
      : 'rgb(235, 240, 243)'
    document.body.style.color = isDarkModeActive ? 'white' : 'black'
  }, [isDarkModeActive])

  const defaultHome = () => {
    if (pathname === '/' && !(location.state && location.state.from))
      navigate(`/projects-screen/${user?._id}`)
  }

  const redirectToIntro = () => {
    if (pathname === '/' && !(location.state && location.state.from) && !user)
      window.location.href = `https://intro.hutupia.com`
  }

  useEffect(() => {
    defaultHome()
    redirectToIntro()
  }, [pathname])

  const UnAuthorised = <div>Unauthorized access</div>

  const props = {
    socket,
    isDarkModeActive,
  }

  if (location.pathname !== '/sitemap.xml')
    return (
      <>
        <Suspense fallback={<LazyHutupiaCover />}>
          <Routes>
            <Route exact path='/' element={<RedirectPage />} />
            <Route
              exact
              path='/projects-screen/:userId'
              element={user ? <ProjectsScreenPage {...props} /> : UnAuthorised}
            />
            <Route
              exact
              path='/ProjectProfile/:projectId'
              element={user ? <ProjectProfilePage {...props} /> : UnAuthorised}
            />
            <Route exact path='*' element={<BrokenLink {...props} />} />
          </Routes>
        </Suspense>
        <ScrollToUp />
      </>
    )
}

export default App
